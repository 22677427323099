:root {
    /*Margins & Paddings*/
    --padding: 1rem; 
    --margin: 1rem; 
    --vertical-padding: 1rem; /* must NOT be a percentage value */
    --vertical-margin: 1rem; /* must NOT be a percentage value */
    /*Breakpoints*/
    --breakpoint-m: 1100px;
    --breakpoint-s: 800px;
    --breakpoint-xs: 550px;
    --breakpoint-menu: 1300px;
    /*General*/
    --container-max-width: 1100px;
    --fixed-header-height: 80px;
    /*Colors*/
    --text-color: #303030;
    --body-bg: #fff;
    --main-color: #b5ceab;
    --accent-color: #39959a;
    --effect-color: #93cfd2;
    --header-text-color: --text-color;
    --header-bg-color: #fff;
    --footer-bg-color: var(--main-color);
    --footer-text-color: #303030;
    --menu-text-color: #a5a299;
    --menu-text-hover-color: var(--text-color); 
    --map-marker-color: ff0000;
    /*Fonts*/
    --base-font-family: "OpenSans", "Helvetica", "Arial", sans-serif;
    --alternative-font-family: "Noto Serif", "Helvetica", "Arial", sans-serif;
}


@import "/fontmodules/OpenSans.css";
@import "/fonts/Noto Serif/all.css"; /* 400, 400i, 700, 700i */
@import "/fontmodules/Quicksand.css";


@import "/cssmodules/normalize.css";
    @import "/cssmodules/leaflet2.css";
    @import "/cssmodules/basic_styling.css";
@import "/cssmodules/flex_grid.v1.1.css";
@import "/cssmodules/slick.css";
@import "/cssmodules/slick-theme.css";
@import "/cssmodules/menu.v1.2.css";
@import "/cssmodules/magnific-popup.css";
/*@import "/cssmodules/animate.css";*/





.link {
    text-decoration: underline;
    color: #2e6100;
}



.fa-map-marker{
    background: url('data:image/svg+xml;utf8,%3Csvg%20fill%3D%22%23var(--map-marker-color)%22%20width%3D%221792%22%20height%3D%221792%22%20viewBox%3D%220%200%201792%201792%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M1152%20640q0-106-75-181t-181-75-181%2075-75%20181%2075%20181%20181%2075%20181-75%2075-181zm256%200q0%20109-33%20179l-364%20774q-16%2033-47.5%2052t-67.5%2019-67.5-19-46.5-52l-365-774q-33-70-33-179%200-212%20150-362t362-150%20362%20150%20150%20362z%22%2F%3E%3C%2Fsvg%3E');
    background-size: cover;
    padding: 15px;
    background-position: center;
    font-size: 0;
}

.markerLabels {
    overflow: visible !important;
}

.topheader {
    background: #7b886f;
    color: white;
    padding: 5px 0px;

}
.topheader h4 {
    margin: 0px;
    padding-left: 20px;
    padding-right: 20px;
}

.layout {
    align-items: flex-start;
}


/*------------------------------------------------------------------------------------------------ 
GENERAL ----------------------------------------------------------------------------------------*/



body {
    font-family: var(--base-font-family);
    line-height: 1.5;
    font-weight: 400;
    color: var(--text-color);
    background: var(--body-bg);
    border-top: 90px solid transparent;
}
p {
    margin: 3px 0 5px;
}
a, a:hover {
    text-decoration: none;
    color: #303030;
}
img {
    max-width: 100%;
    height: auto;
    flex: none;
    display: block;
}

.imagetop {
    min-height: 55vh;
    background: no-repeat center center;
    background-size: cover;
    border-bottom: 4px solid #7b886f;
    border-top: 4px solid #7b886f;
}

figure {
    margin: 0px;
}
.flex > * > img {
    flex: 0 0 auto;
}
/* cross-browser fix, obs! gäller endast för bilder i div utan klass*/
.flex > * > img:not([class]) {
    width: 100%;
}

.button {
    margin: 1rem 0px;
    background: #7B886F;
    border: none;
    font-weight: 400;
    color: #fff;
    transition: background .4s;
}

.button:hover {
    background: #b5ceab;
    color: #fff;

}

/* ------------------------------- FONTS + HEADINGS ---------------------------- */
h1, h2, h3, h4, h5, h6 {
    font-family: var(--alternative-font-family);
    margin: 0.5em 0; 
    font-weight: 700;
    margin-bottom: 0px;

}
.homepage h1 {
    margin: 0px 0px; 
}
.slick-title h2 {
    margin-bottom: 0.5em;

}
.slick-title {
    font-size: 1.2rem;
    font-family: var(--base-font-family);
    text-align: center;
    background: rgba(144, 154, 134, 0.75);
    color: #fff;
    padding: 0 10px;
    max-width: 1500px;
    margin: 0 auto;
    font-weight: 400;
}
/*Slick arrows*/
.slick-prev, .slick-next {width:100px; height:100px; border:none;position:absolute; z-index:1; }  
.slick-prev {left:0px;} .slick-next {right:0px;}
.slick-prev:before, .slick-next:before { font-size:100px; color:#fff; opacity:0.9; font-family: Quicksand, sans-serif; }
.slick-prev:before {content:"<";} .slick-next:before {content:">";}
/* ------------- WRAPPERS ------------- */
.container.sml {
    max-width: 900px;
}
.container.med {
    max-width: 1300px;
}
.container.lrg {
    max-width: 1500px;
}
.container {
    width: 100%; /*fixar issue med bredd i vissa flexelement i IE*/
}
.wrapper {
    min-height: calc(100vh - 90px);
    position: relative;
    /*margin-top: 80px; För att flytta ner innehåll vid position:fixed på header*/
}

/*------------------------------------------------------------------------------------------------ 
STARTSIDA ----------------------------------------------------------------------------------------*/

/* ---------------SLIDER----------- */
.slick {
    margin-bottom: 0;
    
}
.slick-track, .slick-substitute {
    display: flex;
    flex-direction: row;
    
} 
.slick-initialized .slick-slide, .slick-substitute > div {
    height: 100%;
    min-height: 70vh;
    width:100%;
    display: flex;
    background-size: cover;
    background-position: center center;
    border-bottom: 4px solid #7b886f;
    border-top: 4px solid #7b886f;

}

/*------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------- 
CONTENT -----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------*/

/* ----------------STARTTEXT------------  */
.starttext {
    text-align: center;
}

/* ------------------STARTBOXARNA----------- */
.startbox {
    height: 39vh;
    background-size: cover;
    background-position: center center;
}
a.boxwrapper {
    background-color: rgba(0, 0, 0, 0);
    height: 100%;
    transition:background 0.5s ease-in 0s;
}
.startbox:hover a.boxwrapper {
    background: rgba(109, 106, 106, 0.3);  
    transition:background 0.5s ease-in 0s;
}
a.boxwrapper h2 {
    transition:color 0.5s ease-in 0s;
}
.startbox:hover a.boxwrapper h2 {
    color:#ffffff;
    transition:color 0.5s ease-in 0s;
}


/*------------------------------------------------------------------------------------------------ 
Nyheter & Nyhet--------------------------------------------------------------------------------*/
/* -----------------NYHETER---------------- */

.artikelbild {
    float: right;
    max-width: 40%;
    height: auto;
    margin: 0 0 15px 15px
}
.newsitem hr {
    color: #efefef;
}

/*------------------------------------------------------------------------------------------------ 
General content--------------------------------------------------------------------------------*/

/* ----------------PAGINAION------------  */

div.pagination {
    margin: 2rem auto 0;
    padding-bottom: 1vh;
    list-style: none;
    width:100%;
    text-align: center;
}

ul.pagination  li {
    display: inline-block;
    padding-right: 5px;
}

ul.pagination  li a {
    background: #000;
    color: white;
    text-decoration: none;
    padding: 4px 8px;
}

.pagination .active a, .pagination a:hover {
    background: #303030;
}


/* -------------- logo gallery ------------ */
.logotype-gallery {
    padding: 2em 0;
}
.logotype-image {
    min-height: 70px;
} 
.logotype-image div, .logotype-image a {
    text-align: center; 
    width: 100%; 
    flex:0 0 auto; 
    display: block;
} 
.logotype-image img {
    height: auto;
} 
.standing  {
    width:40%; 
} 
.squarish  {
    width:40%; 
} 
.landscape  {
    width:55%; 
}
.longlandscape {
    width:70%; 
}
.extralonglandscape {
    width:100%; 
}

/* -------------- mfp-ajax ------------ */

.mfp-ajax-holder .wrapper{
    min-height: auto;
}

.mainAjaxContent :first-child{
    margin-top: 0;
}

.mfp-ajax-holder{
    background: #FFF;
    padding: 20px 30px;
    text-align: left;
    max-width: 650px;
    margin: 40px auto;
    position: relative;
    height: calc(100% - 80px);
    overflow-y: auto;
}

.mfp-close {
    margin: 0;
}


/*------------------------------------------------------------------------------------------------ 
HEADER -----------------------------------------------------------------------------------------*/

/* ------------NAV + HEADER--------------- */
header {
    background: var(--header-bg-color);
    z-index: 10;
    width: 100%;
    position: fixed; /*om fixed el absolute, glöm ej margin-top på main*/
    top: 0;
}
.logo {
    padding: 20px;
}
.logo img {
    max-height: 50px; 
    width: auto; 
}
.logo a, .logo img {
    display: block;
}
header nav {}
header nav ul.menu {}
header nav ul.menu li {}
header nav ul.menu li a {
    color: #7b886f; /* #b5ceab;*/
    font-size: 1.1em;
    letter-spacing: 1px;
    font-weight: 400;
    text-transform: uppercase;
    white-space: nowrap;
    transition: color 0.5s ease;
}
.menu.menu li a:hover, .menu.menu li.active a {
    color: #303030;
}

nav ul.menu li.dropdown > a:after { 
    content: "▾"; display: inline-block; margin: 0 0 0 5px; 
}
header nav ul.menu .dropdown ul,  ul.sub-menu-items {
    background: #fff;
}

/*----------------------------MOBILMENY----------------------------*/
@media (max-width: var(--breakpoint-menu)) 
{
    header nav ul.menu.show, header nav ul.menu.menu, header nav ul.menu li a,  header nav ul.menu .dropdown ul,  ul.sub-menu-items {
        background: #fff;

    }
    .menu.show {
        max-height: calc(100vh - 80px);
    }
    header nav ul.menu li a {
        font-size: 1em;
    }

}
    
    

/*------------------------------------------------------------------------------------------------ 
FOOTER & KONTAKT--------------------------------------------------------------------------------*/
/* -----------------FOOTER---------------- */
footer {
    background-color: var(--footer-bg-color);
    padding: 30px 0;
}
footer, footer a, footer a:hover { 
    color: var(--footer-text-color); 
}
footer svg {
    margin-right: 10px;
    fill: var(--footer-text-color);
}
footer h4 {
    margin-top:0;
}

/* -----------------Intendit-footer---------------- */
.created-by {
    background: var(--footer-bg-color);
    text-align: center;
    padding: 10px 0; 
}
p.intenditfooter, p.intenditfooter a, p.intenditfooter a:hover  {
    color: var(--footer-text-color);
    line-height:1.1;
}
p.intenditfooter {
   margin:0;
}
/* -----------------KONTAKT---------------- */


/* -----------------KONTAKTFORMULÄR---------------- */
.boltform form { 
    display: flex; 
    flex-wrap: wrap; 
    flex-direction: row;
}

.boltforms-row {
    flex-basis: calc(100% - 20px);
    padding-right: 10px;
    padding-left: 10px;
    box-sizing: content-box;
}

.boltforms-six-row {
    flex-basis: calc(50% - 20px);
}

.boltforms-four-row {
    flex-basis: calc((100% / 3) - 20px);
}

.boltforms-three-row {
    flex-basis: calc(25% - 20px);
}

.boltform textarea, .boltform input {
    width: 100%;
}


@media (max-width: var(--breakpoint-s))
{
    .boltforms-row {
        flex-basis: auto;
    }
    
    .boltform form {
        flex-direction: column;
    }
}

.formwrapper {
    background-color: #ececec; 
    padding: 20px 0px;
}

.boltforms-row button {
    margin: 0 auto;
    display: block;
    background: #7b886f;
    border: none;
    font-weight: 400;
}

.boltforms-row button:hover {
    background: #b5ceab;
    color: #fff;
}
/* --------------- Google maps --------------------*/
/* Give map a height and it's images no max-width */
.map-canvas {
    height: 45vh;
}

.map-canvas img {
    max-width: none;
}

/*------------------------------------------------------------------------------------------------ 
MEDIA QUERIES ----------------------------------------------------------------------------------*/
/*----------------------------Allt under mobilmeny----------------------------*/
@media (max-width: var(--breakpoint-menu)) 
{
    
}

/*----------------------------Allt under 1500px----------------------------*/
@media (max-width:1500px)
{
    .container.lrg {
        width: 96%; 
    } 
    
    .formwrapper {
        margin-top: 1vh;
    }
}
/*----------------------------Allt under 1300px----------------------------*/

@media (max-width:1300px)
{
    .container.med {
        width: 96%; 
    } 
}

/*----------------------------Allt under 1100px----------------------------*/
@media (max-width: var(--breakpoint-m))
{
   
    .container {
        width: 96%; 
    } 
    /*Slick arrows*/   
    .slick-prev, .slick-next { height: 70px; width: 70px;}
    .slick-prev:before, .slick-next:before { font-size: 70px;} 

}
/*----------------------------Allt under 800px----------------------------*/
@media (max-width: var(--breakpoint-s))
{
    .container.sml {
        width: 96%; 
    } 
    
    footer .s-twelve {
        text-align: center; 
    }
    footer .s-twelve a {
        display:block; 
        text-align:center; 
        margin: 0 auto; 
    } 
   
}
/*---------------------------MEDIA XS - upp till 550-----------------------------*/
@media (max-width: var(--breakpoint-xs))
{ 
    .logo img {
        max-height: 40px;
    }
    .wrapper {
        min-height: calc(100vh - 80px);
        position: relative;
    }
    body {
        border-top: 80px solid transparent
    }
}




.showmore {
    height:0;
    transition: opacity 1s ease-out;
    opacity: 0; 
    display: none;
}

.showmore.more {
    display: block;
    height:auto;
    transition: opacity 1s ease-out;
    opacity: 1; 
    margin-top:1em;
}

.button.moreinfo, .button {
    color:white;
}

.button:hover {
    color:white;
}
